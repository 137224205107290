<template>
  <div class="wrapper">
    <base-button
      icon type="primary"
      class="flotingBtn"
      :class="[isActive ? 'tagBtn2' : 'tagBtn']"
      @click="isActive = !isActive"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-right"></i></span>
    </base-button>
    <base-button
      icon type="primary"
      class="flotingBtn2"
      @click="toggleClass"
      :class="[isActive ? 'tagBtn' : 'tagBtn2']"
    >
      <span class="btn-inner--icon"><i class="ni ni-bold-left"></i></span>
    </base-button>
    <Sidebar :class="{tagBtn: isActive}" />
    <div class="main-content" :class="{DashboardBody: isActive}">
        <SampleNavbar/>
        <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-success">
            <b-breadcrumb>
                <b-breadcrumb-item href="#">Client</b-breadcrumb-item>
                <b-breadcrumb-item href="#" @click="$router.push({name: 'client-list'});">Client List</b-breadcrumb-item>
                <b-breadcrumb-item href="#" @click="$router.back()">Client Products</b-breadcrumb-item>
                <b-breadcrumb-item href="#" active>Client Fallowup</b-breadcrumb-item>
            </b-breadcrumb>
        </base-header>
        <b-container fluid class="mt--7">
            <b-row  class="mb-2">
              <b-col md="10"></b-col>
              <b-col md="2"><base-button block type="primary" @click="csvExport(csvData)">Export to CSV</base-button></b-col>
            </b-row>
            <b-card type="default" no-body>
              <pulse-loader v-if="loding" :color="color" :size="size" />
                <vue-good-table
                :columns="columns"
                :rows="rows"
                :line-numbers="true"
                :search-options="{
                enabled: true,
                placeholder: 'Search this table',
                }"
                :pagination-options="{
                enabled: true,
                mode: 'records'
                }"
                >
                  <!-- <template slot="loadingContent">
                    
                  </template> -->
                  <template slot="table-row" slot-scope="props" style="w">
                    <!-- <div> animated loading....</div> -->
                    <span v-if="props.column.field == 'after'">
                        <base-button
                        icon type="primary"
                        size="sm"
                        >
                        <span class="btn-inner--icon"><i class="fas fa-list-alt"></i></span>
                        </base-button>
                        <base-button
                        icon type="danger"
                        size="sm"
                        @click="checkedClick(props.row.client_id)"
                        >
                        <span class="btn-inner--icon">
                          <i class="ni ni-check-bold" v-if="props.row.client_status"></i>
                          <i class="ni ni-fat-remove" v-else></i>
                        </span>
                        </base-button>
                    </span>
                    <span v-else>
                        {{props.formattedRow[props.column.field]}}
                    </span>
                  </template>
                </vue-good-table>
            </b-card>
        </b-container>
        <Footer/>
    </div>
  </div>
</template>
<script>
import Sidebar from './../../Starter/Sidebar'
import SampleNavbar from './../../Starter/SampleNavbar'
import Footer from './../../Starter/SampleFooter'
import { API_URL } from '../../../router/api'
export default {
  name: 'client-fallowup',
  components: {
    Sidebar,
    SampleNavbar,
    Footer
  },
  data () {
    return {
      color: '#5e72e4',
      size: '30px',
      margin: '2px',
      radius: '2px',
      loding: true,
      isActive: false,
      columns: [
        {
          label: 'Employee Name',
          field: 'employee_name'
        },
        {
            label: 'Fallowup Date',
            field: 'client_fallowup_date'
        },
        {
            label: 'Fallowup Time',
            field: 'client_fallowup_time'
        },
        {
            label: 'Fallowup Text',
            field: 'client_fallowup_text'
        },
        // {
        //   label: 'Actions',
        //   field: 'after'
        // }
      ],
      rows: []
    }
  },
  computed: {
    csvData () {
      return this.rows.map(item => ({
        ...item
      //  address: 'адрес', // item.address.city,
      //  company: 'компания'// item.company.name
      }))
    },
    // rows () {
    //   return this.$store.state.clientProductList.filter((clientData) => {
    //       return clientData.client_id === this.clientId
    //   })
    // }
  },
  methods: {
    toggleClass: function (event) {
      this.isActive = !this.isActive
    },
    checkedClick(id) {
      this.rows.map(row => {
        if(row.client_id === id) {
          row.client_status = !row.client_status; 
          let active = row.client_status;
          this.axios.post(API_URL+'admin_client_active',{id: id, active: active})
          .then((res) => {
            let status = res.data.status;
            let message = res.data.message;
            if(status == true) {
                this.$swal.fire({
                  icon: 'success',
                  title: 'Oops...',
                  text: `${message}`,
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                })
            }else {
              this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `${message}`,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              })
            }
          })
        }
        return row
      })
    },
    csvExport (arrData) {
      let csvContent = 'data:text/csv;charset=utf-8,'
      csvContent += [
        Object.keys(arrData[0]).join(';'),
        ...arrData.map(item => Object.values(item).join(';'))
      ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '')

      const data = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', data)
      link.setAttribute('download', 'client-products.csv')
      link.click()
    }
  },
  mounted() {
    let id = JSON.parse(this.$route.params.id);
    this.axios.get(API_URL+'admin_client_fallowup/'+id)
    .then((response) => {
    let obj = response.data
    this.rows = obj
    this.loding = false;
    })
  }
}
</script>
<style lang="scss">
.DashboardBody {
  margin-left: 0px !important;
}
.tagBtn {
  display: none !important;
}
.tagBtn2 {
  display: block !important;
}
table.vgt-table {
    font-size: 12px;
}
</style>
